import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika"} />
		<Helmet>
			<title>
				FitZone
			</title>
			<meta name={"description"} content={"FitZone'u keşfedin, fitness için ideal destinasyonunuz."} />
			<meta property={"og:description"} content={"FitZone'u keşfedin, fitness için ideal destinasyonunuz."} />
			<link rel={"shortcut icon"} href={"https://varuntarest.com/img/43625757.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://varuntarest.com/img/43625757.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://varuntarest.com/img/43625757.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://varuntarest.com/img/43625757.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://varuntarest.com/img/43625757.png"} />
			<meta name={"msapplication-TileImage"} content={"https://varuntarest.com/img/43625757.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" text-align="left" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Gizlilik Politikası
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Bu Gizlilik Politikası (“Politika”), varuntarest.com (“biz”, “bizi” veya “bizim”) olarak, ziyaretçilerin ve kullanıcıların (“siz” veya “sizin”) varuntarest.com web sitesi ve ilgili hizmetler (topluca “Hizmetler” olarak anılacaktır) aracılığıyla kişisel verilerini nasıl topladığımızı, kullandığımızı, aktardığımızı ve koruduğumuzu açıklar.
				<br />
				<br />
				Hizmetlerimizi kullanarak, bu Politikada açıklanan uygulamaları kabul etmiş olursunuz. Bu Politikayı kabul etmiyorsanız, lütfen Hizmetlerimizi kullanmayın.
				<br />
				<br />
				1. Topladığımız Bilgiler
				<br />
				<br />
				Aşağıdaki türde bilgileri toplayabiliriz:
				<br />
				<br />
				1.1 Kişisel Veriler: Adınız, e-posta adresiniz, posta adresiniz ve Hizmetlerimizle etkileşimde bulunduğunuzda gönüllü olarak sağladığınız diğer bilgiler gibi kişisel verileri toplayabiliriz.
				<br />
				<br />
				1.2 Kullanım Bilgileri: IP adresiniz, tarayıcı türünüz, işletim sisteminiz ve ziyaret ettiğiniz sayfalar gibi Hizmetlerimizle etkileşimleriniz hakkında kişisel olmayan bilgileri toplayabiliriz. Bu bilgileri toplamak için çerezler ve benzeri teknolojiler kullanabiliriz.
				<br />
				<br />
				2. Bilgilerinizi Nasıl Kullanıyoruz
				<br />
				<br />
				Verilerinizi şu amaçlarla kullanıyoruz:
				<br />
				<br />
				2.1 Hizmetlerin Sunulması ve İyileştirilmesi: Sorularınızı ve taleplerinizi yanıtlamak da dahil olmak üzere, Hizmetlerimizi sunmak, sürdürmek ve iyileştirmek için bilgilerinizi kullanıyoruz.
				<br />
				<br />
				2.2 Analiz: Kullanıcı eğilimlerini ve tercihlerini analiz etmek için analiz araçlarını kullanıyoruz, bu da Hizmetlerimizi geliştirmemize yardımcı oluyor.
				<br />
				<br />
				3. Bilgilerinizin Paylaşımı
				<br />
				<br />
				Kişisel bilgilerinizi üçüncü şahıslara satmıyor, kiralamıyor veya takas etmiyoruz. Ancak, bilgilerinizi şu durumlarda paylaşabiliriz:
				<br />
				<br />
				3.1 Yasal Gereklilikler: Bilgilerinizi, yasalar, düzenlemeler, yargı süreçleri veya devlet talepleri gereği ifşa edebiliriz.
				<br />
				<br />
				3.2 Hakların Korunması: Haklarımızı, gizliliğimizi, güvenliğimizi veya mülkiyetimizi korumak ya da herhangi bir kişinin güvenliğini tehdit eden acil bir duruma yanıt vermek için bilgilerinizi ifşa etmemiz gerektiğine inandığımızda bilgilerinizi paylaşabiliriz.
				<br />
				<br />
				4. Tercihleriniz
				<br />
				<br />
				Kişisel bilgilerinizle ilgili olarak aşağıdaki seçeneklere sahipsiniz:
				<br />
				<br />
				4.1 Erişim ve Düzeltme: Kişisel bilgilerinize erişebilir ve bunları düzeltmek için support@varuntarest.com adresinden bizimle iletişime geçebilirsiniz.
				<br />
				<br />
				4.2 Reddetme: Tarafımızdan reklam e-postaları almayı reddedebilirsiniz, bu e-postalardaki talimatları izleyerek.
				<br />
				<br />
				5. Güvenlik
				<br />
				<br />
				Verilerinizi korumak için makul idari, teknik ve fiziksel önlemler kullanıyoruz. Ancak, internet üzerinden yapılan hiçbir veri aktarımı veya elektronik depolama yöntemi tamamen güvenli değildir ve mutlak güvenliği garanti edemeyiz.
				<br />
				<br />
				6. Çocukların Gizliliği
				<br />
				<br />
				Hizmetlerimiz 13 yaşın altındaki kişiler için tasarlanmamıştır. 13 yaşından küçük bir çocuğun bilgilerini yanlışlıkla topladığımıza inanıyorsanız, lütfen bizimle iletişime geçin, bu bilgileri derhal sileceğiz.
				<br />
				<br />
				7. Gizlilik Politikasındaki Değişiklikler
				<br />
				<br />
				Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Veri işleme uygulamalarımız hakkında bilgi sahibi olmak için lütfen bu Politikayı periyodik olarak gözden geçirin.
				<br />
				<br />
				8. Bizimle İletişime Geçin
				<br />
				<br />
				Bu Gizlilik Politikası veya veri işleme uygulamalarımız hakkında herhangi bir sorunuz, endişeniz veya isteğiniz varsa, lütfen support@varuntarest.com adresinden bizimle iletişime geçin.
			</Text>
		</Section>
		<Components.Footer background="#fff" />
	</Theme>;
});